<script>
  let name = "yearly-magnetograms";
  let year = parseInt(new Date().getFullYear());
  let url;
  let urlF;
  let urlD;
  let urlH;
  let urlZ;
  let selectedDate,
    selectedTimestamp,
    nextTimpestamp,
    nextDate,
    prevTimestamp,
    prevDate;
    
  let availableYears = [];
  let availableDays = [];
  let availableHours = [];
  let base_url =
      "https://observatory.geophysik.uni-muenchen.de/geomagnetism/data/datenYear/"

  for (let y = 2001; y <= parseInt(new Date().getFullYear()); y++) {
    availableYears.push(y);
  }

  $: {
    console.log("update url");

    selectedDate = new Date(year, 0, 1, 0, 0, 0);
    selectedTimestamp = Math.floor(selectedDate.getTime() / 1000);

    year = parseInt(new Date(selectedTimestamp * 1000).getFullYear());

    //formatstring for javascript date

    url =
      base_url +
      year;

    urlF =
      url +
      "_F.JPG";

    urlD =
      url +
      "_D.JPG";

    urlH =
      url +
      "_H.JPG";

    urlZ =
      url +
      "_Z.JPG";
  }

  function goNext() {
    nextTimpestamp = selectedTimestamp + (365 * 24 * 60 * 60);
    nextDate = new Date(nextTimpestamp * 1000);
    year = parseInt(nextDate.getFullYear());
  }

  function goPrev() {
    prevTimestamp = selectedTimestamp - (365 * 24 * 60 * 60);
    prevDate = new Date(prevTimestamp * 1000);
    year = parseInt(prevDate.getFullYear());
  }

  function goNow() {
    let timestamp = Math.floor(Date.now() / 1000);
    let now = new Date(timestamp * 1000);
    year = parseInt(now.getFullYear());
  }

</script>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  form {
    max-width: 100%;
    max-height:auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    max-height: auto;
  }


  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

</style>

<!--<svelte:options tag="yearly-magnetograms" /> -->

<main>
  <form action="" method="GET">
    <!-- <label for="year">year:</label> -->
    <select bind:value={year} name="year" id="year" class="form-select col-md-2 ms-md-auto">
      {#each availableYears as ayear}
        <option value={ayear}>{ayear}</option>
      {/each}
    </select>
  </form>
  <form action="" method="get" id="navigation">
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goPrev}
      value="Prev"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNow}
      value="Now"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNext}
      value="Next"
    />
  </form>
  <div>
    <img
      src={urlF}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlD}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlH}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlZ}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
</main>
